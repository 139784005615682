import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import modalsSlice from '../../reducers/modalsSlice'
import { selectCurrentLyrics } from '../../selectors/current-play-selectors'
import player from '../../services/Player'
import { Target } from '../../types'
import { traverseLyrics2 } from '../../util/lyrics/traverse-lyrics'
import TargetCharacterRangeVisitor from '../../util/visitors/TargetCharacterRangeVisitor'
import doSplit from './doSplit'

const startEdit = createAsyncThunk<void, Target, { state: RootState; dispatch: AppDispatch }>(
  'playAction/startEdit',
  (target, { dispatch, getState }) => {
    if (!player.isEditMode) {
      return
    }
    const targetCharacterRangeVisitor = new TargetCharacterRangeVisitor(target)
    traverseLyrics2(player.defaultGamer.vizBuilder.sections, targetCharacterRangeVisitor)
    const editRange = targetCharacterRangeVisitor.targetRange
    dispatch(modalsSlice.actions.setLyricEditRange(editRange))
  }
)

const cancelEditTarget = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'playAction/cancelEditTarget',
  (_, { dispatch, getState }) => {
    dispatch(modalsSlice.actions.setLyricEditRange(null))
  }
)

const updateEditTarget = createAsyncThunk<
  void,
  { newText: string },
  { state: RootState; dispatch: AppDispatch }
>('playAction/updateEditTarget', ({ newText }, { dispatch, getState }) => {
  dispatch(cancelEditTarget())
  const lyrics = selectCurrentLyrics(getState())
  if (lyrics !== newText) {
    dispatch(currentPlaySlice.actions.setLyrics(newText))
    dispatch(currentPlaySlice.actions.setIsLyricsDirty(true))
    dispatch(doSplit({ isNeedUserInput: false, gamer: player.defaultGamer })) // TODO: make progress show here!
    // TODO: if numWords changed propagate change (e.g. maxScore)
  }
})

export { cancelEditTarget, startEdit, updateEditTarget }
