import React, { ChangeEvent, createRef, KeyboardEvent } from 'react'

import { addOrUpdateMatchInfo, switchActiveBlaster } from '../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { PlayerSlotList, selectIsPlaying } from '../../selectors/current-play-selectors'
import { selectMatchStatus } from '../../selectors/match-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectPlayerVisibility,
} from '../../selectors/session-selectors'
import Util from '../../util/util'
import { SelectWithLabel } from './TextInputWithLabel'

type Props = {
  gamerIndex: number
  players: PlayerSlotList
}
export const PlayerMenu = ({
  gamerIndex,
  players: { activePlayerIndex, list: players2 },
}: Props) => {
  const dispatch = useAppDispatch()
  const playerMenuRef = createRef<HTMLSelectElement>()
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const isPlaying = useAppSelector(selectIsPlaying)
  const currentUsername = useAppSelector(selectCurrentUsername)
  const matchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const { info: matchInfo, leaderboard } = matchStatus
  const { guestOrder: currentGuestOrder = [] } = matchInfo

  const setNewBlaster = React.useCallback(
    (newBlasterSlug: string) => {
      dispatch(switchActiveBlaster({ gamerIndex, newBlasterSlug }))
    },
    [dispatch, gamerIndex]
  )

  if (matchOwner !== currentUsername) {
    return null
  }
  const players = players2.map(({ playerId, playerName }) => {
    return { value: playerId, name: playerName }
  })
  const safePlayerIndex =
    activePlayerIndex >= 0 && activePlayerIndex < players2.length ? activePlayerIndex : 0
  const selectedPlayerId = players2[safePlayerIndex].playerId
  const onLoadLast = () => {
    // setIsInited(false)
  }
  const onShuffle = () => {
    const newOrder = Util.shuffleArray(players2.map(({ playerId }) => playerId))
    const newMatchInfo = { ...matchInfo, guestOrder: newOrder }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    // setIsInited(false)
  }
  const onSort = () => {
    const sortedGuestPlayers: string[] = []
    leaderboard.forEach((compoundPlayer) => {
      const [username, player] = compoundPlayer.split('/')
      if (username === currentUsername) {
        sortedGuestPlayers.push(player)
      }
    })
    const newMatchInfo = { ...matchInfo, guestOrder: sortedGuestPlayers }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    // setIsInited(false)
  }
  const onChangePlayer = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      const blasterSlug = event.target.value
      setNewBlaster(blasterSlug)
    }
  }
  const onKeyDown = (event: KeyboardEvent) => {
    const step = event.keyCode === 40 ? 1 : event.keyCode === 38 ? -1 : 0
    if (playerMenuRef.current && step !== 0) {
      const newIndex =
        (playerMenuRef.current.selectedIndex + step + players.length) % players.length
      playerMenuRef.current.selectedIndex = newIndex
      setNewBlaster(players[newIndex].value)
      event.stopPropagation()
    }
  }
  return (
    <div className="playerMenu">
      {playerVisibility.isShowGuestPlayers && players.length > 0 && (
        <SelectWithLabel
          onRef={playerMenuRef}
          itemArray={players}
          label=""
          onChange={onChangePlayer}
          onKeyDown={onKeyDown}
          value={selectedPlayerId}
        />
      )}
      {!isPlaying && playerVisibility.isShowGuestPlayers && (
        <div>
          {currentGuestOrder.length > 0 && !players.length && (
            <button className="chatNewTrackButton" onClick={onLoadLast}>
              Load Players
            </button>
          )}
          {false && players.length > 0 && (
            <button className="chatNewTrackButton" onClick={onShuffle}>
              Shuffle
            </button>
          )}
          {false && players.length > 0 && (
            <button className="chatNewTrackButton" onClick={onSort}>
              Sort
            </button>
          )}
        </div>
      )}
    </div>
  )
}
