import StartAudio from 'startaudiocontext'
import * as Tone from 'tone'

import { togglePlay } from '../actions/play-actions'
import { updateMatchStatus } from '../actions/social/leaderboard-actions'
import { AppDispatch } from '../reducers'
import currentPlaySlice from '../reducers/currentPlaySlice'
import { LocalTiming, ModelPart, ModeType } from '../types'
import { isMobile } from '../util/track-utils'
import Util from '../util/util'
import { NullAudioPlayer, ToneAudioPlayer } from './AudioWrappers'
import { LyricVizBuilder } from './LyricVizBuilder'
import Gamer from './Gamer'

type GamerInfo = {
  gamerId: string
  isActive: boolean
  vizBuilder: LyricVizBuilder
  gamer: Gamer
}
export class Player {
  username: string
  _dispatch: AppDispatch | null
  isDragging: boolean
  _audioPlayers: { [key: string]: NullAudioPlayer }
  _player: NullAudioPlayer
  _volume: number
  _isReady: boolean
  _isPlaying: boolean
  isPlayMode: boolean
  isEditMode: boolean
  isVizMode: boolean
  isSpellMode: boolean
  isToneScheduled: boolean
  latencySeconds: number
  prevSeconds: number
  _scoreSeconds: number
  private _scoreClockLeft: HTMLInputElement | null
  private _scoreClockRight: HTMLInputElement | null
  private _clock: HTMLInputElement | null
  private _scrubber: HTMLInputElement | null
  private _duration: HTMLInputElement | null
  _audioFileInput: HTMLInputElement | null
  _localAudioFileToUpload: File | undefined
  _imageFileInput: HTMLInputElement | null
  _localImageFileToUpload: File | undefined
  _interactionContainer: HTMLElement | null
  _gamers: GamerInfo[]
  synth: any

  constructor() {
    this.username = ''
    this._dispatch = null
    this.isDragging = false
    this._audioPlayers = {
      default: new NullAudioPlayer(),
    }
    this._player = this._audioPlayers.default
    this.prevSeconds = 0.0
    this._scoreSeconds = 0.0

    this._volume = 5.0
    this._isReady = false
    this._isPlaying = false
    this.isPlayMode = true
    this.isEditMode = false
    this.isVizMode = false
    this.isSpellMode = false
    this.isToneScheduled = false
    this.latencySeconds = 0
    this._audioFileInput = null
    this._imageFileInput = null
    this._interactionContainer = null
    this._scoreClockLeft = null
    this._scoreClockRight = null
    this._clock = null
    this._scrubber = null
    this._duration = null
    this._gamers = []
  }

  addGamer(gamerId: string) {
    const nextIndex = this._gamers.length
    const gamer = new Gamer(nextIndex, this)
    const gamerInfo = {
      isActive: false,
      gamerId,
      gamer,
      vizBuilder: new LyricVizBuilder(gamer),
    }
    this._gamers.push(gamerInfo)
    this.dispatch(currentPlaySlice.actions.setGamerId({ gamerIndex: nextIndex, gamerId }))
  }
  _toggleGamerActive(gamer: Gamer, timingLines: string[], localTiming?: LocalTiming) {
    setTimeout(() => {
      gamer.init(this.dispatch)
      gamer.initUI()
      gamer._loadLRC({ asReference: !this.isEditMode, lrcLines: timingLines })

      if (localTiming) {
        // TODO: compatible with viz?
        const localTimingLines = localTiming.timing.split(/[\r\n]+/g)
        this.setScoreSeconds(localTiming.scoreSeconds)
        gamer._loadLRC({ lrcLines: localTimingLines, asReference: false })
      }
      gamer._resetTarget()
    }, 200)
  }

  toggleGamerActive(
    gamerIndex: number,
    isActive: boolean,
    timingLines: string[],
    localTiming?: LocalTiming
  ) {
    const gamerInfo = this._gamers[gamerIndex]
    if (gamerInfo) {
      this.stop()
      gamerInfo.isActive = isActive
      if (isActive) {
        this._toggleGamerActive(
          gamerInfo.gamer,
          timingLines,
          gamerIndex === 0 ? localTiming : undefined
        )
      }
    }
  }
  init(dispatch: AppDispatch, latencyMillis: number, username: string) {
    this._dispatch = dispatch
    this.latencySeconds = latencyMillis / 1000
    this.username = username
    this._audioFileInput = document.getElementById('local-audio') as HTMLInputElement
    this._imageFileInput = document.getElementById('local-image') as HTMLInputElement
    this._interactionContainer = document.getElementById('interactionContainer')
    this.synth = new Tone.Synth().toDestination()

    this.addGamer(username)
    this.addGamer('')

    // this.synth.triggerAttackRelease('C5', '16n')
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault()
    })
  }

  initUI() {
    this.stop()
    this._scoreSeconds = 0.0
    this.prevSeconds = 0.0
    this.rewindToStart()

    this.interactionContainer.scrollTop = 0
    this.interactionContainer.scrollLeft = 0
    this.updateClock()

    if (!this.isToneScheduled) {
      this.isToneScheduled = true
      Tone.Transport.scheduleRepeat(this.updateClock.bind(this), '0.01s')
      Tone.Transport.scheduleRepeat(this.updateAnimation.bind(this), '0.2s')
      Tone.Transport.scheduleRepeat(this.updateMatchStatus.bind(this), '5.0s')
      if (isMobile()) {
        StartAudio(Tone.context, document.body, function () {
          // this.keyboard.buttons.activate();
        })
      }
    }
  }

  lyricTimingLoaded(timingLines: string[], localTiming?: LocalTiming) {
    this._gamers.forEach(({ gamer, isActive }, index) => {
      if (isActive) {
        this._toggleGamerActive(gamer, timingLines, localTiming)
      }
    })
  }

  get defaultGamer() {
    return this._gamers[0]?.gamer
  }

  switchMode(mode: ModeType) {
    const isSwitchToViz = mode === 'viz'
    const isSwitchToEdit = mode === 'edit'
    const isSwitchToPlay = mode === 'play'
    const isSwitchToSpell = mode === 'spell'
    this.isPlayMode = isSwitchToPlay
    this.isVizMode = isSwitchToViz
    this.isEditMode = isSwitchToEdit
    this.isSpellMode = isSwitchToSpell

    this._gamers.forEach((gamerInfo) => {
      gamerInfo.gamer.switchMode(mode)
    })
  }
  get dispatch(): AppDispatch {
    if (!this._dispatch) {
      throw new Error('dispatch not set')
    }
    return this._dispatch
  }
  get audioFileInput(): HTMLInputElement {
    if (!this._audioFileInput) {
      throw new Error('audioFileInput not set')
    }
    return this._audioFileInput
  }
  get imageFileInput(): HTMLInputElement {
    if (!this._imageFileInput) {
      throw new Error('imageFileInput not set')
    }
    return this._imageFileInput
  }
  set clock(clock: HTMLInputElement) {
    this._clock = clock
  }
  set scoreClockLeft(scoreClock: HTMLInputElement) {
    this._scoreClockLeft = scoreClock
  }
  set scoreClockRight(scoreClock: HTMLInputElement) {
    this._scoreClockRight = scoreClock
  }
  set duration(duration: HTMLInputElement) {
    this._duration = duration
  }
  set scrubber(scoreClock: HTMLInputElement) {
    this._scrubber = scoreClock
  }
  get interactionContainer(): HTMLElement {
    if (!this._interactionContainer) {
      throw new Error('interactionContainer not set')
    }
    return this._interactionContainer
  }
  get hasFocus() {
    return this.interactionContainer.parentElement?.classList.contains('hasFocus')
  }

  get player(): NullAudioPlayer {
    return this._player
  }
  // set player(playerKey: string) {
  //   const newPlayer = this._audioPlayers[playerKey]
  //   this._player = newPlayer || this._audioPlayers['default']
  // }
  get volume() {
    return this._volume
  }
  get isRewound() {
    return Tone.Transport.seconds === 0
  }

  setAudioPlayer(playerKey: string, player?: NullAudioPlayer) {
    if (player) {
      this._audioPlayers[playerKey] = player
    }
    const newPlayer = this._audioPlayers[playerKey] || this._audioPlayers['default']
    newPlayer.volume = this.volume
    this._player = newPlayer
  }
  set volume(value) {
    this._volume = value
    this.player.volume = value
  }

  get trackDuration() {
    return this.player.trackDuration
  }

  get userInputAudioFile() {
    return this._localAudioFileToUpload
  }
  set userInputAudioFile(localAudioFile: File | undefined) {
    this._localAudioFileToUpload = localAudioFile
    this.audioFileInput.value = ''
  }

  get userInputImageFile() {
    return this._localImageFileToUpload
  }
  set userInputImageFile(localAudioFile: File | undefined) {
    this._localImageFileToUpload = localAudioFile
    this.imageFileInput.value = ''
  }

  get isReady() {
    return this._isReady
  }

  set isReady(isReady) {
    this._isReady = isReady
  }

  get isPlaying() {
    return this._isPlaying
  }

  set isPlaying(isPlaying) {
    this._isPlaying = isPlaying
  }

  get isBlasting() {
    return this.isPlaying && this.isPlayMode
  }

  resume() {
    this.isReady = true
  }

  suspend() {
    this.isReady = false
  }
  updateAnimation() {
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.updateAnimation()
      }
    })
  }

  set playerBuffer(newBuffer: Tone.ToneAudioBuffer | null) {
    if (newBuffer) {
      this.setAudioPlayer('tone', new ToneAudioPlayer(newBuffer))
    } else {
      this._player = this._audioPlayers['default'] // TODO: clear tone buffer?
    }
    const trackSeconds = newBuffer ? newBuffer.duration : 0
    this.dispatch(currentPlaySlice.actions.setTrackDuration(trackSeconds))
  }

  // Audio Manager
  start() {
    if (!this.player || this.isPlaying) {
      return // already started
    }
    const startAt = Tone.Transport.seconds
    Tone.Transport.start()
    this.isPlaying = true
    this.player.start(startAt)
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.start()
      }
    })
  }

  // Audio Manager
  stop() {
    if (!this.player || !this.isPlaying) {
      return // already stopped
    }
    Tone.Transport.pause()
    this.player.stop()
    this.isPlaying = false
    this.dispatch(currentPlaySlice.actions.setClockSeconds(Tone.Transport.seconds))
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.stop()
      }
    })
  }
  rewindToStart() {
    if (this.player) {
      // might not have player (e.g. if error loading song) TODO: always have default player?
      this.player.seek(0)
    }
    Tone.Transport.seconds = 0
    this.setClockTo(0)
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.rewindToStart()
      }
    })
  }
  rewindTo(seconds: number, part: ModelPart | null = null) {
    if (seconds == null) {
      console.log('null secs') // TODO: why will this ever happen? just default to 0?
      return
    }
    // console.log(`rewindTo ${seconds} [${part.label}]`);
    this.player.seek(seconds)
    Tone.Transport.seconds = seconds
    this.setClockTo(seconds)
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer._rewindTo(seconds, part)
      }
    })
  }
  clearGamerTimings() {
    this._gamers.forEach((gamerInfo) => {
      if (gamerInfo.isActive) {
        gamerInfo.gamer.clearTiming()
      }
    })
  }
  clearTimingForGamer(gamerIndex: number) {
    if (0 <= gamerIndex && gamerIndex < this._gamers.length) {
      const gamerInfo = this._gamers[gamerIndex]
      if (gamerInfo.isActive) {
        gamerInfo.gamer.clearTiming()
      }
    }
  }
  updateClock() {
    const clockSeconds = Tone.Transport.seconds
    if (clockSeconds > this.trackDuration) {
      this.dispatch(togglePlay())
      this.rewindToStart()
      return
    }
    // const clockText = (clockSeconds === 0) ? '00:00.000' : Util.secondsToClock(clockSeconds);
    const clockText = Util.secondsToClock(clockSeconds)
    if (this._clock && this._scrubber) {
      this._clock.value = clockText
      this._scrubber.value = String(clockSeconds)
    }
    const durationText = Util.secondsToClock(this.trackDuration - clockSeconds)
    if (this._duration) {
      this._duration.value = durationText
    }
    // this.dispatch(currentPlaySlice.actions.setClockSeconds(clockSeconds));
    this.setScoreSeconds(clockSeconds)
  }

  setScoreSeconds(clockSeconds: number) {
    const { _scoreSeconds, prevSeconds } = this
    const newScoreSeconds = _scoreSeconds + (clockSeconds - prevSeconds)
    this.prevSeconds = clockSeconds
    this._scoreSeconds = newScoreSeconds
    const clockText = Util.secondsToClock(newScoreSeconds)
    if (this._scoreClockLeft) {
      this._scoreClockLeft.value = clockText
    }
    if (this._scoreClockRight) {
      this._scoreClockRight.value = clockText
    }
    // const numLaps = Math.floor(newScoreSeconds / trackDuration);
    // this.dispatch(currentPlaySlice.actions.updateScoreClock({ numLaps, scoreSeconds }));
  }

  setClockTo(time: number) {
    this.prevSeconds = time
    if (this._clock && this._scrubber) {
      this._clock.value = Util.secondsToClock(time)
      this._scrubber.value = String(time)
    }
    this.dispatch(currentPlaySlice.actions.setClockSeconds(time))
  }

  updateMatchStatus() {
    if (this.isPlaying && !this.isVizMode) {
      this.dispatch(updateMatchStatus(-1))
    }
  }
}

const defaultPlayer = new Player()
export default defaultPlayer
