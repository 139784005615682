import React, { ChangeEvent, Component } from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import PlayArea from './play-area/PlayArea' // actions must come after to avoid circular reference - TODO: fix!
import { removeLocalTiming } from '../actions/authoring/local-storage-actions'
import { loadLocalAudio } from '../actions/authoring/loadLocalAudio'
import { loadMatch, matchSwitchedTo } from '../actions/load-match'
import loadTrackFromUri from '../actions/track-loading/loadTrackFromUri'
import { BLASTER_URL_PREFIX, BLEND_MODES, DEFAULT_BACKGROUNDS } from '../constants/constants'
import { NODE_ASSETBASE } from '../constants/environment'
import logo from '../assets/img/icon2.png'
import QuickStartModal from './modals/QuickStartModal'
import SocialContainer from './social/SocialContainer'
import TermsOfUseModal from './modals/TermsOfUseModal'
import UserSettingsModal from './modals/user-settings/UserSettingsModal'
import modalsSlice, {
  OpenMatchInfo,
  OpenPlaylistInfo,
  OpenTrackGalleryInfo,
} from '../reducers/modalsSlice'
import sessionSlice from '../reducers/sessionSlice'
import { selectReadyPeers } from '../selectors/blaster-peer-selectors'
import {
  selectCurrentUsername,
  selectIsShowSocial,
  selectMode,
} from '../selectors/session-selectors'
import trackMixer from '../services/Player'
import realtimeInstance from '../services/RealtimeService'
import localStorageInstance from '../services/UserLocalStorage'
import userManager from '../services/UserManager'
import { isMobile } from '../util/track-utils'
import DeviceSupport from '../util/DeviceSupport'
import keyBindings from '../services/KeyBindings'
import history from '../services/History'
import BreakTimer from './modals/BreakTimerModal'
import ErrorModal from './modals/ErrorModal'
import MatchInfoModal from './modals/match-info/MatchInfoModal'
import ModalBackdrop from './modals/ModalBackdrop'
import TrackGalleryModal from './modals/TrackGalleryModal'
import TrackInfoModal from './modals/TrackInfoModal'
import PlaylistModal from './modals/PlaylistModal'
import SyncModal from './modals/SyncModal'
// import PlayArea from './play-area/PlayArea' // must come before actions (above) to avoid circular reference
import themes, { ThemeCssVariable } from './themes'
import JoinMatchModal from './modals/JoinMatchModal'
import Footer from './Footer'
import { AppDispatch, RootState } from '../reducers'
import { ActiveMatchInfo, AudienceType, Options, TrackActions, UploadHelpers } from '../types'
import { MatchStatusState } from '../reducers/matchStatusSlice'
import { loadLocalImage } from '../actions/authoring/loadLocalImage'
import { selectImageData, selectImagePath } from '../selectors/current-play-selectors'
import GameSidebar from './game-sidebar/GameSidebar'

interface Props extends RouteComponentProps {
  appInfo: any
  themeIndex: number
  dispatch: AppDispatch
  readyPeers: string[]
  activeMatches: ActiveMatchInfo[] | null
  trackError: string
  matchInfo: MatchStatusState
  currentTrackPath: string
  isShowOnStartup: boolean
  isQuickStartOpen: boolean
  isSessionInitialized: boolean
  isUserSettingsOpen: boolean
  isTrackInfoOpen: boolean
  isLeaderboardModalOpen: boolean
  openTrackGalleryInfo: OpenTrackGalleryInfo
  isSyncModalOpen: boolean
  isJoinMatchModalOpen: boolean
  isShowSocial: boolean
  currentBackgroundIndex: number
  currentBackgroundBlendMode: number
  remoteImagePath: string | undefined
  localImageData: ArrayBuffer | string | null
  userHasAgreedToTermsOfUse: boolean
  openPlaylistInfo: OpenPlaylistInfo
  openMatchInfo: OpenMatchInfo
  currentUsername: string
  isShowCountdownClock: boolean
  isBreakTimerModalOpen: boolean
}

// TODO: convert to functional component?
class App extends Component<Props> {
  state = {
    userHasSeenQuickStart: false,
    isAppInitialized: false,
  }
  isAlreadyJoining = false
  isError = false
  alreadyLoading = false
  userHasAgreedToTermsOfUse = false

  componentDidMount() {
    const {
      appInfo: { /* analytics, */ deviceSupport },
      themeIndex,
    } = this.props
    ReactGA.pageview(window.location.pathname + window.location.search)
    // Analytics.init(analytics)
    DeviceSupport.init(deviceSupport)
    if (!DeviceSupport.isSupported()) {
      return
    }
    const options: Options = {
      contentVariant: 'dev',
      clearStorage: false, // TODO: support?
    }
    userManager.init(this.props.dispatch, options)
    this._updateTheme(themeIndex)
    this._initialize()
  }

  componentDidUpdate(prevProps: Props) {
    this._initialize()
    const {
      location: { pathname },
      themeIndex,
    } = this.props
    const lastRequestedPath = localStorageInstance.lastRequestedPath
    if (lastRequestedPath) {
      localStorageInstance.lastRequestedPath = ''
      history.replace(lastRequestedPath)
      return
    }
    const {
      location: { pathname: prevPathname },
      readyPeers: prevReadyUsers,
      themeIndex: prevThemeIndex,
    } = prevProps
    if (themeIndex !== prevThemeIndex) {
      this._updateTheme(themeIndex)
    }
    this._handlePath({ pathname, prevPathname, prevReadyUsers })
  }

  _handlePath({
    pathname,
    prevPathname,
    prevReadyUsers,
  }: {
    pathname: string
    prevPathname: string
    prevReadyUsers: string[]
  }) {
    const {
      dispatch,
      readyPeers,
      activeMatches,
      trackError,
      currentTrackPath,
      openTrackGalleryInfo,
    } = this.props
    if (trackError || (this.isError && pathname === prevPathname)) {
      return
    }
    const needsFirstMatch = activeMatches && activeMatches.length === 0
    if (needsFirstMatch && !this.isAlreadyJoining) {
      dispatch(modalsSlice.actions.toggleJoinMatchModal(true))
      this.isAlreadyJoining = true // TODO: yuk, but easiest way to show only once
      return
    }
    if (currentTrackPath === pathname && userManager.lastTrackUri !== currentTrackPath) {
      userManager.lastTrackUri = currentTrackPath
      return
    }
    try {
      const workingPathname = pathname.length > 1 ? pathname : userManager.lastTrackUri
      const pathParts = workingPathname.split('/').filter((part: string) => !!part)
      const [prefix, ...payloadParts] = pathParts
      const numPathParts = payloadParts.length
      switch (prefix) {
        case BLASTER_URL_PREFIX:
          if (numPathParts && numPathParts < 4) {
            throw new Error('invalid play url')
          }
          const [matchOwner] = payloadParts
          const isUserReady = readyPeers.includes(matchOwner)
          const isPrevUserReady = prevReadyUsers.includes(matchOwner)
          const isLoadStateChanged = pathname !== prevPathname || isUserReady !== isPrevUserReady
          if (isLoadStateChanged && isUserReady) {
            dispatch(
              modalsSlice.actions.toggleTrackGalleryInfo({ ...openTrackGalleryInfo, isOpen: false })
            )
            dispatch(loadTrackFromUri({ trackPath: workingPathname }))
            if (isMobile()) {
              dispatch(sessionSlice.actions.setIsShowSocial(false))
            }
          }
          break
        default:
          if (!this.alreadyLoading && activeMatches && activeMatches.length) {
            const { matchOwner, matchSlug } = activeMatches[0]
            const initialUserReady = readyPeers.includes(matchOwner)
            if (initialUserReady) {
              dispatch(loadMatch({ matchOwner, matchSlug, isLoadFirstTrack: true })).then(() => {
                dispatch(matchSwitchedTo({ matchOwner, matchSlug }))
              })
              this.alreadyLoading = true
            }
          }
      }
    } catch (pathError: any) {
      this.isError = true // TODO: and yuk
      const trackError = pathError?.message || 'unknown path error'
      console.log(`ignoring path because ${pathError}`)
      dispatch(sessionSlice.actions.setTrackError(trackError))
    }
  }

  _updateTheme(newThemeIndex: number) {
    const theme = themes[newThemeIndex]
    if (theme) {
      const { cssVars } = theme
      // Update css variables in document's root element
      for (const key in cssVars) {
        const value = cssVars[key as ThemeCssVariable]
        document.documentElement.style.setProperty(`--${key}`, value)
      }
    }
  }

  _initialize() {
    const { isAppInitialized } = this.state
    const { isSessionInitialized, userHasAgreedToTermsOfUse, dispatch } = this.props
    if (!isAppInitialized && isSessionInitialized && userHasAgreedToTermsOfUse) {
      trackMixer.init(dispatch, userManager.latencyMillis, userManager.username)
      keyBindings.init(dispatch)
      realtimeInstance.init(dispatch)
      window.addEventListener('resize', () => {
        const appearsToBeFullScreen =
          // eslint-disable-next-line no-restricted-globals
          window.innerWidth === screen.width && screen.height - window.innerHeight < 300
        // eslint-disable-next-line no-restricted-globals
        // console.log(
        //   appearsToBeFullScreen,
        //   // eslint-disable-next-line no-restricted-globals
        //   window.innerWidth,
        //   // eslint-disable-next-line no-restricted-globals
        //   window.innerHeight,
        //   // eslint-disable-next-line no-restricted-globals
        //   screen.width,
        //   // eslint-disable-next-line no-restricted-globals
        //   screen.height
        // )
        this.props.dispatch(sessionSlice.actions.setIsFullscreen(appearsToBeFullScreen))
      })
      this.setState({ isAppInitialized: true })
    }
  }

  _getQuickStartModal = () => {
    const { userHasSeenQuickStart, isAppInitialized } = this.state
    const { isShowOnStartup, isQuickStartOpen } = this.props
    const showQuickStart =
      isAppInitialized && (isQuickStartOpen || (isShowOnStartup && !userHasSeenQuickStart))

    if (showQuickStart) {
      const onClose = () => {
        keyBindings.popModal()
        this.props.dispatch(modalsSlice.actions.toggleHelpModal(false))
        this.setState({ userHasSeenQuickStart: true })
      }
      const onUpdateSettings = (isShowOnStartup: boolean) => {
        userManager.isQuickStart = isShowOnStartup
      }
      return <QuickStartModal onUpdateSettings={onUpdateSettings} onClose={onClose} />
    }
  }

  _getUserSettingsModal = () => {
    if (this.props.isUserSettingsOpen) {
      const onClose = () => {
        keyBindings.popModal()
        this.props.dispatch(modalsSlice.actions.toggleUserSettings(false))
      }
      const onSwitchTheme = (newThemeIndex: number) => {
        userManager.theme = newThemeIndex
        this.props.dispatch(sessionSlice.actions.setCurrentThemeIndex(newThemeIndex))
      }
      const onSwitchDefaultMode = (mode: string) => {
        // @ts-ignore
        const defaultMode = mode === 'last' ? `last-${this.props.mode}` : mode
        userManager.defaultMode = defaultMode
      }
      const onSwitchAudience = (rawAudience: string) => {
        const audience = rawAudience as AudienceType
        userManager.audience = audience
      }
      const onSetLatencyMillis = (latencyMillis: number, isUseLatency: boolean) => {
        const latency = { latencyMillis, isUseLatency }
        trackMixer.latencySeconds = isUseLatency ? latencyMillis / 1000 : 0
        userManager.latency = latency
      }
      return (
        <UserSettingsModal
          manager={userManager}
          onClose={onClose}
          onSwitchAudience={onSwitchAudience}
          onSwitchTheme={onSwitchTheme}
          onSwitchDefaultMode={onSwitchDefaultMode}
          onSetLatencyMillis={onSetLatencyMillis}
        />
      )
    }
  }

  _getTrackInfoModal = (trackActions: TrackActions) => {
    if (this.props.isTrackInfoOpen) {
      const onClose = () => {
        keyBindings.popModal()
        this.props.dispatch(modalsSlice.actions.toggleTrackInfo(false))
      }
      return <TrackInfoModal onClose={onClose} trackActions={trackActions} />
    }
  }

  _getPlaylistInfoModal = () => {
    const {
      currentUsername,
      openPlaylistInfo: { matchSlug: compoundMatchSlug, slug, title, isNew },
    } = this.props
    if (compoundMatchSlug && (slug || isNew)) {
      const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
      if (matchOwner !== currentUsername) {
        return null // TODO: support?
      }
      const onClose = () => {
        keyBindings.popModal()
        this.props.dispatch(modalsSlice.actions.togglePlaylistInfo({}))
      }
      return (
        <ModalBackdrop isTop>
          <PlaylistModal
            matchSlug={matchSlug}
            playlistSlug={slug}
            playlistTitle={title}
            onClose={onClose}
          />
        </ModalBackdrop>
      )
    }
  }

  _getMatchInfoModal = () => {
    const { slug: matchSlug, owner: matchOwner } = this.props.openMatchInfo
    if (matchOwner) {
      return (
        <MatchInfoModal
          currUserId={this.props.currentUsername}
          matchOwner={matchOwner}
          matchSlug={matchSlug || ''}
        />
      )
    }
  }
  _getTrackGalleryModal = () => {
    const {
      openTrackGalleryInfo: { isOpen },
      dispatch,
    } = this.props
    if (isOpen) {
      const onClose = () => {
        keyBindings.popModal()
        dispatch(modalsSlice.actions.toggleTrackGalleryInfo({ isOpen: false }))
      }
      return <TrackGalleryModal onClose={onClose} />
    }
  }

  _getSyncModal = (uploadHelpers: UploadHelpers) => {
    if (this.props.isSyncModalOpen) {
      const onClose = () => {
        this.props.dispatch(modalsSlice.actions.toggleSyncModal(false))
      }
      return <SyncModal uploadHelpers={uploadHelpers} onClose={onClose} />
    }
  }

  _getJoinMatchModal = () => {
    if (this.props.isJoinMatchModalOpen) {
      const onClose = () => {
        this.props.dispatch(modalsSlice.actions.toggleJoinMatchModal(false))
      }
      return <JoinMatchModal onClose={onClose} />
    }
  }

  _getErrorModal = () => {
    if (this.props.trackError) {
      const onClose = () => {
        userManager.lastTrackUri = ''
        window.location.href = '/'
      }
      return <ErrorModal onClose={onClose} />
    }
  }

  _getBreakTimerModal = () => {
    if (this.props.isBreakTimerModalOpen) {
      const onClose = () => {
        this.props.dispatch(modalsSlice.actions.toggleBreakTimerModal(false))
      }
      return <BreakTimer onClose={onClose} />
    }
  }

  _getPageBody = () => {
    const {
      props: {
        userHasAgreedToTermsOfUse,
        isShowSocial,
        isLeaderboardModalOpen,
        dispatch,
        currentBackgroundIndex,
        currentBackgroundBlendMode,
        remoteImagePath,
        localImageData,
      },
    } = this
    if (!userHasAgreedToTermsOfUse) {
      return (
        <div className="pageBody">
          <TermsOfUseModal />
        </div>
      )
    }
    // const player = trackMixer.gamer
    const trackActions = {
      chooseLocalFile: () => trackMixer.audioFileInput.click(),
      chooseLocalImageFile: () => trackMixer.imageFileInput.click(),
    }
    const uploadHelpers = {
      getCurrentLRC: () => trackMixer.defaultGamer.currentLRC,
      getLocalAudioFile: () => trackMixer.defaultGamer.userInputAudioFile,
      getLocalImageFile: () => trackMixer.defaultGamer.userInputImageFile,
    }
    const settingsActions = {
      copyReference: () => trackMixer.defaultGamer.useReferenceTiming(),
      toggleTimes: (isShow: boolean) => trackMixer.defaultGamer.toggleTimes(isShow),
      toggleMasked: (isShow: boolean) => trackMixer.defaultGamer.toggleMasked(isShow),
      toggleVisualPulse: (isPulse: boolean) => {
        trackMixer.defaultGamer.isVisualPulseActiveWord = isPulse
      },
      toggleAudioPulse: (isPulse: boolean) => {
        trackMixer.defaultGamer.isAudioPulseActiveWord = isPulse
      },
    }
    const playActions = {
      // blast: (isLeft: boolean) => trackMixer.blast(isLeft),
      rewindToStart: () => trackMixer.rewindToStart(),
      rewindTo: (time: number) => trackMixer.rewindTo(time),
      clear: () => trackMixer.clearGamerTimings(),
      forget: () => {
        trackMixer.clearGamerTimings()
        dispatch(removeLocalTiming())
      },
      delete: () => {
        // TODO: remove from local storage and/or remote...
      },
    }
    const playerActions = { settingsActions, playActions }
    const wantTrackImage = currentBackgroundIndex >= DEFAULT_BACKGROUNDS.length
    const workingBackgroundImageIndex = Math.min(
      currentBackgroundIndex,
      DEFAULT_BACKGROUNDS.length - 1
    )
    const bgImageName = DEFAULT_BACKGROUNDS[workingBackgroundImageIndex]
    const bgImageUrl = localImageData
      ? `url('${localImageData}')`
      : wantTrackImage && remoteImagePath
      ? `url('${remoteImagePath}')`
      : `url('${NODE_ASSETBASE}images/LB_${bgImageName}.png')`
    const backgroundBlendMode = BLEND_MODES[currentBackgroundBlendMode]
    return (
      <div className="pageBody" style={{ backgroundImage: bgImageUrl, backgroundBlendMode }}>
        {this._getUserSettingsModal()}
        {this._getQuickStartModal()}
        {this._getTrackInfoModal(trackActions)}
        {this._getJoinMatchModal()}
        {this._getMatchInfoModal()}
        {this._getPlaylistInfoModal()}
        {this._getTrackGalleryModal()}
        {this._getSyncModal(uploadHelpers)}
        {this._getErrorModal()}
        {this._getBreakTimerModal()}
        {isLeaderboardModalOpen && <GameSidebar />}
        <PlayArea actions={playerActions} isShowSocial={isShowSocial} />
        {isShowSocial && <SocialContainer />}
      </div>
    )
  }

  _getLocalAudio = () => {
    // const localAudioRef = React.createRef<HTMLInputElement>()
    const onLocalAudioChanged = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) {
        return
      }
      const selectedFile = event.target.files[0]
      const {
        props: { dispatch },
      } = this
      dispatch(loadLocalAudio({ songFile: selectedFile }))
      // if (localAudioRef.current) {
      //   localAudioRef.current.value = ''
      // }
    }
    return (
      <input
        id="local-audio"
        // ref={localAudioRef}
        className="hidden"
        tabIndex={-1}
        type="file"
        onChange={onLocalAudioChanged}
      />
    )
  }

  _getLocalImage = () => {
    // const localAudioRef = React.createRef<HTMLInputElement>()
    const onLocalImageChanged = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target || !event.target.files) {
        return
      }
      const selectedFile = event.target.files[0]
      const {
        props: { dispatch },
      } = this
      dispatch(loadLocalImage({ imageFile: selectedFile }))
      // if (localAudioRef.current) {
      //   localAudioRef.current.value = ''
      // }
    }
    return (
      <input
        id="local-image"
        // ref={localAudioRef}
        className="hidden"
        tabIndex={-1}
        type="file"
        onChange={onLocalImageChanged}
      />
    )
  }

  render() {
    // TODO: hide/change message when logging out somehow
    if (!this.props.isSessionInitialized) {
      return (
        <div className="initializing">
          <img src={logo} alt="" />
          <div>initializing...</div>
        </div>
      )
    }
    return (
      <main className="app">
        <div id="page" className="page">
          {/* {this._getCountdownClock()} */}
          {this._getPageBody()}
          <Footer currentUsername={this.props.currentUsername} />
          {this._getLocalAudio()}
          {this._getLocalImage()}
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const {
    prefs: {
      quickStart: { isShowOnStartup },
      termsOfUse: { userHasAgreed: userHasAgreedToTermsOfUse },
      lastTrackUri,
    },
    isInitialized: isSessionInitialized,

    matches: { active: activeMatches },
    trackError,
    currentBackgroundIndex,
    currentThemeIndex: themeIndex,
    currentBackgroundBlendMode,
    currentTrackPath,
  } = state.session
  const {
    isUserSettingsOpen,
    isTrackInfoOpen,
    openMatchInfo,
    openPlaylistInfo,
    isSyncModalOpen,
    isJoinMatchModalOpen,
    openTrackGalleryInfo,
    isBreakTimerModalOpen,
    isHelpModalOpen: isQuickStartOpen,
    isLeaderboardModalOpen,
  } = state.modals
  const remoteImagePath = selectImagePath(state)
  const localImageData = selectImageData(state)
  const readyPeers = selectReadyPeers(state)
  const currentUsername = selectCurrentUsername(state)
  const isShowSocial = selectIsShowSocial(state)
  const mode = selectMode(state)
  const matchInfo = state.matchStatus

  const isShowCountdownClock = state.session.isShowCountdownClock

  return {
    activeMatches,
    currentBackgroundIndex,
    currentBackgroundBlendMode,
    currentTrackPath,
    isSessionInitialized,
    isShowOnStartup,
    userHasAgreedToTermsOfUse,
    isShowSocial,
    isUserSettingsOpen,
    isQuickStartOpen,
    isTrackInfoOpen,
    openTrackGalleryInfo,
    isJoinMatchModalOpen,
    isLeaderboardModalOpen,
    isSyncModalOpen,
    localImageData,
    remoteImagePath,
    lastTrackUri,
    matchInfo,
    mode,
    readyPeers,
    currentUsername,
    openMatchInfo,
    openPlaylistInfo,
    themeIndex,
    trackError,
    isShowCountdownClock,
    isBreakTimerModalOpen,
  }
}

export default withRouter(connect(mapStateToProps)(App))
