import { DEFAULT_LYRICS } from '../constants/constants'
import { getSummaryTitle } from '../util/track-utils'
import { selectOwnedMatchSlug, selectOwnedPlaylistMap } from './blaster-peer-selectors'
import {
  selectMatchStatus,
  selectOwnedMatchInfo,
  selectOwnerMatchStatuses,
} from './match-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectPlayerVisibility,
} from './session-selectors'
import { RootState } from '../reducers'
import { BlastStatus, MatchStatusMap, PlayerStatusMap, PlaylistInfoMap } from '../types'

const selectIsPlaying = (state: RootState) => state.currentPlay.isPlaying
const selectIsPlayable = (state: RootState) => state.currentPlay.isPlayable
const selectIsEditable = (state: RootState) => state.currentPlay.isEditable
const selectDirtyState = (state: RootState) => state.currentPlay.dirtyState
const selectIsNeedSyncing = (state: RootState) => {
  const { isAudioDirty, isInfoDirty, isLyricsDirty, isTimingDirty, isImageDirty } =
    state.currentPlay.dirtyState
  return isAudioDirty || isInfoDirty || isLyricsDirty || isTimingDirty || isImageDirty
}
const selectIsScoreDirty = (state: RootState) => state.currentPlay.isScoreDirty
export const selectGamerStatus = (playerIndex: number) => (state: RootState) =>
  state.currentPlay.gamers[playerIndex]
export const selectCurrentGamers = (state: RootState) => state.currentPlay.gamers
const selectCurrentPlayStatus = (playerIndex: number) => (state: RootState) =>
  state.currentPlay.gamers[playerIndex]?.playStatus

const selectCurrentTrackDuration = (state: RootState) => state.currentPlay.trackDuration
const selectCurrentLyrics = (state: RootState) => state.currentPlay.lyrics || DEFAULT_LYRICS
const selectCurrentLRC = (state: RootState) => state.currentPlay.lrcLines
const selectCurrentTrackInfo = (state: RootState) => state.currentPlay.trackInfo
const selectCurrentTrackSlug = (state: RootState) => state.currentPlay.trackInfo.slug
const selectImagePath = (state: RootState) => state.currentPlay.trackInfo.remoteImagePath
const selectImageData = (state: RootState) => state.currentPlay.localImageData
export const selectLastBlasts = (state: RootState): BlastStatus[] =>
  state.currentPlay.gamers.filter(({ isActive }) => isActive).map(({ lastBlast }) => lastBlast)

const selectCurrentTrackSummaryTitle = (state: RootState) =>
  getSummaryTitle(state.currentPlay.trackInfo)
const selectCurrentPlaylistInfo = (state: RootState) => state.currentPlay.playlistInfo
const selectCurrentPlaylistSlug = (state: RootState) => state.currentPlay.playlistInfo.slug
const selectCurrentPlaylistSlugWithPrefix = (state: RootState) => {
  const { slug, prefix } = selectCurrentPlaylistInfo(state)
  return `${prefix}:${slug}`
}

// const selectCurrentTrackTopScore = (state: RootState) => {
//   const { username, currentBlaster: player } = selectCurrentSessionInfo(state)
//   const compoundMatchSlug = selectCurrentMatchSlug(state)
//   const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
//   const trackSlug = selectCurrentTrackSlug(state)
//   return selectPlayerTopScoreForTrack({ matchOwner, matchSlug, username, player, trackSlug })(state)
// }

const selectPlaylistInfoMapForCurrentTrack = (state: RootState) => {
  const currentTrackSlug = selectCurrentTrackSlug(state)
  const owner = selectCurrentUsername(state)
  const { playlistOrder: ownerPlaylistOrder = [] } = selectOwnedMatchInfo(state)
  // should contain playlist info for everything in ownerPlaylistOrder, but can't assume (e.g. if owned match hidden) // TODO: revisit
  const ownerPlaylistMap = selectOwnedPlaylistMap(owner)(state)
  const playlists: PlaylistInfoMap = {}
  ownerPlaylistOrder.forEach((slug) => {
    const ownerPlaylist = ownerPlaylistMap[slug]
    if (ownerPlaylist) {
      const { trackOrder = [], title, wordCount, timestamp, owner } = ownerPlaylist
      const containsCurrentTrack = trackOrder.includes(currentTrackSlug)
      playlists[slug] = {
        slug,
        title,
        containsCurrentTrack,
        trackOrder: [],
        wordCount,
        timestamp,
        owner,
      }
    }
  })
  return playlists
}
const selectMatchInfoMapForOwnedTrack =
  (trackSlug: string) =>
  (state: RootState): MatchStatusMap => {
    const owner = selectCurrentUsername(state)
    const ownedMatchSlug = selectOwnedMatchSlug(state)
    const ownerMatches = selectOwnerMatchStatuses(owner)(state)
    const matchesWithTrack: MatchStatusMap = {}
    Object.keys(ownerMatches).forEach((matchSlug: string) => {
      const matchStatus = ownerMatches[matchSlug]
      const {
        info: { playlists = {}, playlistOrder = [] },
      } = matchStatus
      const isTrackInMatch =
        matchSlug === ownedMatchSlug ||
        playlistOrder.findIndex((playlistSlug) => {
          const { trackOrder = [] } = playlists[playlistSlug] // protection needed, sigh TODO:
          return trackOrder.includes(trackSlug)
        }) >= 0

      if (isTrackInMatch) {
        matchesWithTrack[matchSlug] = matchStatus
        return
      }
    })
    return matchesWithTrack
  }

const selectIsCurrentMatchOwnedMatch = (state: RootState) => {
  const compoundMatchSlug = selectCurrentMatchSlug(state)
  const ownedMatchSlug = selectOwnedMatchSlug(state)
  return compoundMatchSlug === ownedMatchSlug
}
export type PlayerSlotList = {
  activePlayerIndex: number
  list: { playerId: string; playerName: string }[]
}
export const selectListsForPlayerSlots = (state: RootState): PlayerSlotList[] => {
  const currentMatchSlug = selectCurrentMatchSlug(state)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const gamers = selectCurrentGamers(state)
  const hostPlayerName = selectCurrentUsername(state)
  const {
    info: { guestOrder },
    players,
  } = selectMatchStatus(matchOwner, matchSlug)(state)
  const playerVisibility = selectPlayerVisibility(state)
  const currentUserPlayerScores: PlayerStatusMap = players[hostPlayerName] || {}
  const activeGamerIds = gamers.filter(({ isActive }) => isActive).map(({ gamerId }) => gamerId)

  const createInfoForSlot = (slotIndex: number): PlayerSlotList => {
    const otherSlotsToCheck: string[] = []

    // we have at most 2 for now, so just swap the other // TODO: generalize
    if (slotIndex === 0) {
      if (activeGamerIds.length > 1) {
        otherSlotsToCheck.push(activeGamerIds[1])
      }
    } else {
      otherSlotsToCheck.push(activeGamerIds[0])
    }
    const newGuestOrder = [...guestOrder] // TODO: should we be using Set instead, here and perhaps elsewhere?
    if (playerVisibility.isShowHostPlayer && !newGuestOrder.includes(hostPlayerName)) {
      newGuestOrder.push(hostPlayerName)
    }
    const filteredOrder = newGuestOrder.filter((player) => {
      return !otherSlotsToCheck.includes(player)
    })
    const playerList = filteredOrder.map((playerId) => {
      const playerScore = currentUserPlayerScores[playerId]
      const playerName = playerScore ? playerScore.name : playerId
      return {
        playerId,
        playerName,
      }
    })
    const activePlayerIndex = filteredOrder.findIndex(
      (playerId) => playerId === activeGamerIds[slotIndex]
    )
    return {
      activePlayerIndex,
      list: playerList,
    }
  }

  const listsForSlots = activeGamerIds.map((playerId, slotIndex) => {
    return createInfoForSlot(slotIndex)
  })
  return listsForSlots
}

export {
  selectCurrentLyrics,
  selectCurrentLRC,
  selectCurrentPlaylistInfo,
  selectCurrentPlaylistSlug,
  selectCurrentPlaylistSlugWithPrefix,
  selectCurrentTrackInfo,
  selectCurrentTrackSlug,
  selectCurrentTrackSummaryTitle,
  selectImagePath,
  selectImageData,
  selectIsCurrentMatchOwnedMatch,
  selectIsEditable,
  selectIsNeedSyncing,
  selectIsPlaying,
  selectIsPlayable,
  selectIsScoreDirty,
  selectCurrentPlayStatus,
  // selectCurrentTrackTopScore,
  selectCurrentTrackDuration,
  selectDirtyState,
  selectMatchInfoMapForOwnedTrack,
  selectPlaylistInfoMapForCurrentTrack,
}
