import { createAsyncThunk } from '@reduxjs/toolkit'

import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import localAuthoringSlice from '../../reducers/localAuthoringSlice'
import { selectIsLocalTrackDirty } from '../../selectors/local-authoring-selectors'
import player from '../../services/Player'
import { PlaylistInfo } from '../../types'
import setCurrentPlaylist from './setCurrentPlaylist'

const clearSession = createAsyncThunk<
  Promise<void>,
  { matchSlug: string; playlistInfo: PlaylistInfo; trackSlug: string },
  { state: RootState; dispatch: AppDispatch }
>('load/clearSession', ({ matchSlug, playlistInfo, trackSlug }, { dispatch, getState }) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(setCurrentPlaylist({ matchSlug, playlistInfo }))
    player.stop()
    player.clearGamerTimings()
    player.playerBuffer = null
    dispatch(currentPlaySlice.actions.setIsPlayable(false))
    dispatch(currentPlaySlice.actions.setLRClines(null))
    if (trackSlug && !selectIsLocalTrackDirty(trackSlug)(getState())) {
      dispatch(localAuthoringSlice.actions.removeTrack(trackSlug))
    }
    resolve()
  })
})

export default clearSession
