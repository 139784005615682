import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BLASTER_URL_PREFIX,
  defaultGamerStatus,
  defaultMatchInfo,
  defaultMatchScore,
  defaultPlaylistInfo, MAX_LOCAL_BLASTERS,
} from '../constants/constants'
import { AppDispatch, RootState } from '../reducers'
import blasterPeersSlice from '../reducers/blasterPeersSlice'
import matchStatusSlice from '../reducers/matchStatusSlice'
import realtime from '../services/RealtimeService'
import {
  MatchInfo,
  MatchPath,
  MatchStatus,
  PlayerStatus,
  PlayStatusTrackInfo,
  UserMatchStatusMap,
} from '../types'
import { getMatchPlayerNamesByRank } from '../util/score-utils'
import loadTrackFromUri from './track-loading/loadTrackFromUri'
import currentPlaySlice from '../reducers/currentPlaySlice'
import { selectMatchStatus } from '../selectors/match-selectors'
import { selectCurrentUsername } from '../selectors/session-selectors'
import { selectCurrentTrackInfo } from '../selectors/current-play-selectors'

export const matchSwitchedTo = createAsyncThunk<
  void,
  { matchOwner: string; matchSlug: string },
  { state: RootState; dispatch: AppDispatch }
>('load/matchDidSwitch', async ({ matchOwner, matchSlug }, { dispatch, getState }) => {
  const state = getState()
  const username = selectCurrentUsername(state)
  const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
  const { slug: song, wordCount: numElements, duration } = selectCurrentTrackInfo(state)
  const trackInfo: PlayStatusTrackInfo = {
    song,
    trackDuration: duration,
    numElements,
    maxScore: 0,
  }
  const {
    info: { guestOrder = [] }, // TODO: guestOrder shouldn't be empty, but we're not yet guaranteeing this on load of older matches
  } = matchStatus
  for (let gamerIndex = 0; gamerIndex < MAX_LOCAL_BLASTERS; gamerIndex++) {
    const gamerId = gamerIndex < guestOrder.length ? guestOrder[gamerIndex] : username
    dispatch(
      currentPlaySlice.actions.setGamerStatus({
        gamerIndex,
        gamerStatus: defaultGamerStatus(gamerIndex, gamerId, false, trackInfo),
      })
    )
  }
})

export const loadMatch = createAsyncThunk<
  Promise<void>,
  { matchOwner: string; matchSlug: string; isLoadFirstTrack: boolean },
  { state: RootState; dispatch: AppDispatch }
>('load/match', async ({ matchOwner, matchSlug, isLoadFirstTrack }, { dispatch, getState }) => {
  return new Promise<void>(async (resolve, reject) => {
    const rawStatus = await realtime.endpointOnce(`matches/${matchOwner}/${matchSlug}`, {})
    const { players, leaderboard } = getFixedUpPlayersAndLeaderboard({ rawStatus })
    const rawInfo = rawStatus.info as MatchInfo
    const info = { ...defaultMatchInfo(), ...rawInfo }
    const matchStatus: MatchStatus = { info, players, invites: {}, leaderboard: [] }
    dispatch(
      matchStatusSlice.actions.updateMatchStatus({
        matchOwner,
        matchSlug,
        matchStatus,
      })
    )
    dispatch(
      matchStatusSlice.actions.updateLeaderboard({
        matchOwner,
        matchSlug,
        leaderboard,
      })
    )
    const currTracks = null // TODO: may already be loaded?
    const loadTracksPromise = currTracks
      ? Promise.resolve({})
      : realtime.endpointOnce(`tracks/${matchOwner}`)
    const ownerTracks = await loadTracksPromise
    dispatch(blasterPeersSlice.actions.setTracks({ username: matchOwner, tracks: ownerTracks })) // TODO: filter only by public?
    const {
      info: { playlistOrder = [], playlists = {} },
    } = matchStatus
    const firstPlaylist = playlistOrder.length ? playlists[playlistOrder[0]] : defaultPlaylistInfo
    if (isLoadFirstTrack && firstPlaylist) {
      const { slug: firstPlaylistSlug, trackOrder: firstPlaylistTrackOrder = [] } = firstPlaylist
      const [firstTrackSlug] = firstPlaylistTrackOrder
      if (firstTrackSlug) {
        const firstTrackPath = `/${BLASTER_URL_PREFIX}/${matchOwner}/${matchSlug}/${firstPlaylistSlug}/${firstTrackSlug}`
        dispatch(loadTrackFromUri({ trackPath: firstTrackPath }))
      }
    }
    resolve()
  })
})

const getFixedUpPlayersAndLeaderboard = (rawMatchStatus: any = { players2: {} }) => {
  const players = rawMatchStatus.players2 || ({} as UserMatchStatusMap)
  Object.keys(players).forEach((username) => {
    const guestPlayers = players[username]
    Object.keys(guestPlayers).forEach((playerSlug) => {
      const player = guestPlayers[playerSlug] as PlayerStatus
      player.matchScore = player.matchScore || defaultMatchScore()
      player.playlistScores = player.playlistScores || {}
      player.trackScores = player.trackScores || {}
    })
  })
  const leaderboard = getMatchPlayerNamesByRank(players)
  return {
    players,
    leaderboard,
  }
}
export const startListeningToMatch = createAsyncThunk<
  void,
  MatchPath,
  { state: RootState; dispatch: AppDispatch }
>('update/matches', async ({ matchOwner, matchSlug }, { dispatch, getState }) => {
  realtime.endpoint(`matches/${matchOwner}/${matchSlug}/players2`).on('value', (snapshot) => {
    const players2 = snapshot.val() || {}
    const { players, leaderboard } = getFixedUpPlayersAndLeaderboard({ players2 })
    dispatch(
      matchStatusSlice.actions.updateMatchPlayers({
        matchOwner,
        matchSlug,
        players,
      })
    )
    dispatch(
      matchStatusSlice.actions.updateLeaderboard({
        matchOwner,
        matchSlug,
        leaderboard,
      })
    )
  })
})
