import { clone } from 'ramda'
import React, { useEffect } from 'react'

import { defaultMatchInfo } from '../../../constants/constants'
import {
  addOrUpdateMatchInfo,
  toggleLeaderboard,
} from '../../../actions/social/leaderboard-actions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import modalsSlice from '../../../reducers/modalsSlice'
import { selectIsOwnedMatchSlug } from '../../../selectors/blaster-peer-selectors'
import { selectMatchStatus } from '../../../selectors/match-selectors'
import keyBindings from '../../../services/KeyBindings'
import { MatchStatus } from '../../../types'
import { onModalContainerRef } from '../../../util/scrolling'
import Util from '../../../util/util'
import CloseIcon from '../../widgets/CloseIcon'
import ModalBackdrop from '../ModalBackdrop'
import GuestPlayers from './GuestPlayers'
import MatchInfoSection from './MatchInfoSection'
import { MatchInvites } from './MatchInvites'
import { MatchTracks } from './MatchTracks'

type Props = {
  currUserId: string
  matchOwner: string
  matchSlug: string
}

const MatchInfoModal = ({ currUserId, matchOwner, matchSlug }: Props) => {
  const dispatch = useAppDispatch()
  const matchStatus: MatchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))
  const { info: matchInfo, invites: matchInvites, leaderboard, players } = matchStatus
  const { isShareable } = matchInfo || {}
  const isOwner = currUserId === matchOwner
  const [workingMatchSlug] = React.useState(matchSlug || Util.generateId())
  const compoundMatchSlug = `${currUserId}/${workingMatchSlug}`
  const isOwnedMatch = useAppSelector(selectIsOwnedMatchSlug(compoundMatchSlug))
  const [isWatching, setIsWatching] = React.useState(false)
  const onClose = () => {
    keyBindings.popModal()
    dispatch(modalsSlice.actions.toggleMatchInfo(''))
    if (isWatching) {
      dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: false }))
    }
  }
  useEffect(() => {
    if (matchSlug && !isWatching) {
      dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: true }))
      setIsWatching(true)
    }
  }, [dispatch, matchOwner, matchSlug, isWatching])
  const onDuplicate = () => {
    const newMatchSlug = Util.generateId()
    const { title, playlists, playlistOrder } = matchInfo
    const newMatchInfo = {
      ...defaultMatchInfo(),
      title: `Copy of ${title}`,
      slug: `${matchOwner}/${newMatchSlug}`,
      playlistOrder: [...playlistOrder],
      playlists: clone(playlists),
    }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
  }

  const Leaderboard = () => {
    const getLeaderboardRows = () => {
      return leaderboard.map((compoundPlayer, index) => {
        const [username, player] = compoundPlayer.split('/')
        const {
          matchScore: { topScore, topScoreRank },
          name,
        } = players[username][player]
        const isCurrUser = player === currUserId
        const playerClassName = `player${isCurrUser ? ' self' : ''}`
        return (
          <div key={`leaderboard-${index}`} className="row">
            <div className="rank">{index + 1}</div>
            <div className={playerClassName} title={player}>
              {name || player}
            </div>
            <div className={`score ${topScoreRank}2`}>{Util.numberWithCommas(topScore)}</div>
          </div>
        )
      })
    }
    const isLeaderboardEmpty = Object.keys(leaderboard).length === 0
    return (
      <>
        <label>Leaderboard:</label>
        <div className="leaderboard">
          {isLeaderboardEmpty ? <span>(no players yet)</span> : getLeaderboardRows()}
        </div>
      </>
    )
  }
  const title = `${isOwnedMatch ? 'My Collection' : 'Match'} Info`
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer matchInfoModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">{title}</div>
        <div className="twoCol">
          <div className="left">
            <MatchInfoSection
              currUserId={currUserId}
              matchOwner={matchOwner}
              matchSlug={workingMatchSlug}
              matchInfo={matchInfo}
              isOwnedMatch={isOwnedMatch}
            />
            {matchSlug && !isOwnedMatch && (
              <MatchInvites
                currUserId={currUserId}
                matchOwner={matchOwner}
                matchInvites={matchInvites}
                matchSlug={workingMatchSlug}
                isOwnedMatch={isOwnedMatch}
                isShareable={isShareable}
              />
            )}
            {matchSlug && isOwner && !isOwnedMatch && (
              <GuestPlayers compoundMatchSlug={compoundMatchSlug} />
            )}
            {matchSlug && !isOwner && <Leaderboard />}
          </div>
          {matchSlug && isOwner && (
            <div className="right">
              <div>
                <MatchTracks
                  currUserId={currUserId}
                  matchOwner={matchOwner}
                  workingMatchSlug={workingMatchSlug}
                  matchInfo={matchInfo}
                  isOwnedMatch={isOwnedMatch}
                />
                <div className="bottom">
                  <button onClick={onDuplicate}>Duplicate Match</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalBackdrop>
  )
}

export default MatchInfoModal
