import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React from 'react'

import { activateNextPane } from '../../actions/play-actions'
import {
  Pane,
  PaneClasses,
  SHOW_LEADERBOARD_TOOLTIP,
  SHOW_TRACK_GALLERY_TOOLTIP,
} from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectOwnedMatchSlug } from '../../selectors/blaster-peer-selectors'
import {
  PlayerSlotList,
  selectCurrentGamers,
  selectCurrentPlaylistInfo,
  selectCurrentTrackInfo,
  selectListsForPlayerSlots,
} from '../../selectors/current-play-selectors'
import { selectMatchTitle } from '../../selectors/match-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentTrackImageUrl,
  selectCurrentUsername,
  selectHasSpotifyToken,
  selectIsShowSettings,
  selectIsShowSocial,
  selectMode,
} from '../../selectors/session-selectors'
import { SettingsActions } from '../../types'
import { getSpotifyUriFromTrackInfo } from '../../util/track-utils'
import { PlayerMenu } from '../widgets/PlayerMenu'
// import SpotifyPlayback from '../widgets/SpotifyPlayback'
import GameSettings from './GameSettings'
import MovingLaneScore from '../social/MovingLaneScore'
import Util from '../../util/util'

type Props = {
  settingsActions: SettingsActions
}

const HeaderContainer = ({ settingsActions }: Props) => {
  const dispatch = useAppDispatch()
  const trackInfo = useAppSelector(selectCurrentTrackInfo)
  const isShowSocial = useAppSelector(selectIsShowSocial)
  const isShowSettings = useAppSelector(selectIsShowSettings)
  const hasSpotifyToken = useAppSelector(selectHasSpotifyToken)
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.SETTINGS))
  const spotifyUri = getSpotifyUriFromTrackInfo(trackInfo)
  const isUseSpotifyImage = hasSpotifyToken && spotifyUri
  const trackImageUrl = useAppSelector(selectCurrentTrackImageUrl)
  const {
    slug: trackSlug,
    title: trackTitle = '[no track]',
    artist: trackArtist,
    wordCount,
    duration,
  } = trackInfo
  const mode = useAppSelector(selectMode)
  const currentUsername = useAppSelector(selectCurrentUsername)
  const ownedMatchSlug = useAppSelector(selectOwnedMatchSlug)
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const isMatchOwner = matchOwner === currentUsername
  const isOwnedMatch = ownedMatchSlug === currentMatchSlug
  const isBlasting = mode === 'play' && !isOwnedMatch
  const matchTitle = useAppSelector(selectMatchTitle(matchOwner, matchSlug))
  const { title: currentSetTitle } = useAppSelector(selectCurrentPlaylistInfo)
  const gamers = useAppSelector(selectCurrentGamers)
  const activeGamers = gamers.filter(({ isActive }) => isActive)
  const playerSlotLists: PlayerSlotList[] = useAppSelector(selectListsForPlayerSlots)
  const onToggleSocial = () => {
    dispatch(sessionSlice.actions.setIsShowSocial(!isShowSocial))
  }
  const onToggleSettings = () => {
    dispatch(sessionSlice.actions.setIsShowSettings(!isShowSettings))
  }
  const onTrackTitleClick = () => {
    if (trackSlug) {
      dispatch(modalsSlice.actions.toggleTrackInfo(true))
    }
  }
  const trackTitleClass = cx('currTrackTitle', { disabled: !trackSlug })
  const className = cx(PaneClasses[Pane.SETTINGS], { hasFocus: isCurrFocus })
  const onClick = () => {
    // console.log(`container: ${PaneClasses[Pane.SETTINGS]}`)
    dispatch(activateNextPane({ pane: Pane.SETTINGS }))
  }
  const centerClassname = cx('center', { withPlayer: isBlasting })
  const getTrackBadge = () => {
    return (
      <div className="trackBadge">
        {isUseSpotifyImage && (
          <div className="spotifyInfo">
            <img src={trackImageUrl} alt="" />
          </div>
        )}
        <div className={trackTitleClass}>
          <button onClick={onTrackTitleClick} className="title">
            {trackTitle}
          </button>
          <span className="artist">{trackArtist}</span>
        </div>
      </div>
    )
  }
  const showTrackGalleryModal = () => {
    dispatch(modalsSlice.actions.toggleTrackGalleryInfo({ isOpen: true }))
  }
  const showLeaderBoardModal = () => {
    dispatch(modalsSlice.actions.toggleLeaderboardModal(true))
  }
  const getMatchSection = () => {
    return (
      <div className="playerInfo">
        <div className="title">
          <button
            className="x-large"
            onClick={showLeaderBoardModal}
            title={SHOW_LEADERBOARD_TOOLTIP}
          >
            {matchTitle}
          </button>
        </div>
      </div>
    )
  }
  const getLanes = () => {
    const trackPointInfo = () => {
      const durationClockTime = Util.secondsToClock(duration, false, false)
      const pointsPerTrack = Math.floor((10 * wordCount) / duration)
      const pointsPerTrackStr = duration > 0 ? pointsPerTrack : '-'
      const maxScore = pointsPerTrack * wordCount
      const pointsStr = duration > 0 ? maxScore : '-'
      const durationStr = duration > 0 ? durationClockTime : '-'
      const divider = <span>{' / '}</span>

      return (
        <div className="trackInfo">
          <div className="score">
            <div className="points">{pointsStr}</div>
          </div>
          <div className="pointInfo">
            <span>{durationStr}</span>
            {divider}
            <span>{wordCount}</span>
            {divider}
            <span>{pointsPerTrackStr}</span>
          </div>
        </div>
      )
    }
    const getLane = (pos: number) => {
      if (pos >= activeGamers.length) {
        return
      }
      return <MovingLaneScore playerIndex={pos} />
    }

    return (
      <div className="scoreStuff">
        {trackPointInfo()}
        <div className="players">
          {activeGamers.map((_, slotNum) => {
            return <PlayerMenu gamerIndex={slotNum} players={playerSlotLists[slotNum]} />
          })}
        </div>
        <div className="lanes">
          {getLane(0)}
          <div className="divider">
            <div className="padLeft"></div>
            <div className="scale">
              <div className="bad"></div>
              <div className="good"></div>
              <div className="excellent"></div>
              <div className="perfect"></div>
            </div>
            <div className="padRight"></div>
          </div>
          {getLane(1)}
        </div>
      </div>
    )
  }

  return (
    <div className={className} onClick={onClick}>
      <div className="banner">
        <div className="left">
          <div className="edge">
            {/*<button className="bottom" onClick={onToggleSettings}>*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={(isShowSettings ? faAngleDoubleUp : faAngleDoubleDown) as IconProp}*/}
            {/*    title={`${isShowSettings ? 'hide' : 'show'} Play Settings`}*/}
            {/*    size="sm"*/}
            {/*  />*/}
            {/*</button>*/}
          </div>
          <div className="body">
            {getMatchSection()}
            {getTrackBadge()}
          </div>
        </div>
        <div className={centerClassname}></div>
        <div className="right">
          <div className="body">
            <div className="playerInfo">
              <div className="title">
                <button onClick={showTrackGalleryModal} title={SHOW_TRACK_GALLERY_TOOLTIP}>
                  {currentSetTitle || 'Track Gallery'}
                </button>
              </div>
            </div>
            {isMatchOwner && isBlasting && getLanes()}
          </div>
          <div className="edge">
            <button className="top" onClick={onToggleSocial}>
              <FontAwesomeIcon
                icon={(isShowSocial ? faAngleDoubleRight : faAngleDoubleLeft) as IconProp}
                title={`${isShowSocial ? 'hide' : 'show'} Match Explorer sidebar`}
                size="sm"
              />
            </button>
            <button className="bottom" onClick={onToggleSettings}>
              <FontAwesomeIcon
                icon={(isShowSettings ? faAngleDoubleUp : faAngleDoubleDown) as IconProp}
                title={`${isShowSettings ? 'hide' : 'show'} Play Settings`}
                size="sm"
              />
            </button>
          </div>
        </div>
      </div>
      {isShowSettings && <GameSettings settingsActions={settingsActions} />}
    </div>
  )
}

export default HeaderContainer
