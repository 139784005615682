import { createAsyncThunk } from '@reduxjs/toolkit'

import currentPlaySlice from '../../reducers/currentPlaySlice'
import player from '../../services/Player'
import { localAudioSwitched } from '../authoring/update-local-track'
import { setAudio } from '../track-loading/get-track-resources'
import { AppDispatch, RootState } from '../../reducers'

const clearLyricTiming = createAsyncThunk<void, void, { state: RootState; dispatch: AppDispatch }>(
  'load/clearLyricTiming',
  (_, { dispatch }) => {
    dispatch(currentPlaySlice.actions.setIsPlayable(false))
    dispatch(currentPlaySlice.actions.setLRClines(null))
    player.initUI()
  }
)
const audioUrlChanged = createAsyncThunk<void, string, { state: RootState; dispatch: AppDispatch }>(
  'load/audioUrlChanged',
  async (newAudioURL, { dispatch }) => {
    // TODO: validate URL, allow clearing...
    const audioDuration = await dispatch(setAudio({ audioUrl: newAudioURL })).unwrap()
    dispatch(clearLyricTiming())
    dispatch(localAudioSwitched({ remoteURL: newAudioURL, audioDuration }))
    // this.dirtyAudio();
  }
)
type DesktopInfo = {
  fileBuffer: Buffer
  filePath: string
  fileName: string
}
const loadLocalAudio = createAsyncThunk<
  void,
  { desktopInfo?: DesktopInfo; songFile: File },
  { state: RootState; dispatch: AppDispatch }
>('load/localAudio', ({ desktopInfo, songFile }, { dispatch }) => {
  player.stop()
  dispatch(currentPlaySlice.actions.setLRClines(null))
  player.defaultGamer._resetSession()
  dispatch(currentPlaySlice.actions.setIsPlayable(false))
  const setAndUpdate = async ({
    dataURL,
    fileName,
    filePath,
  }: {
    dataURL: ArrayBuffer | string | null
    fileName: string
    filePath?: string
  }) => {
    const audioDuration = await dispatch(setAudio({ audioUrl: dataURL, fileName })).unwrap()
    player.initUI()
    dispatch(localAudioSwitched({ fileName, filePath, audioDuration }))
    dispatch(currentPlaySlice.actions.setLocalAudioPath(fileName))
    dispatch(currentPlaySlice.actions.setIsAudioDirty(true))
  }

  if (desktopInfo) {
    const { fileBuffer, filePath, fileName } = desktopInfo
    setAndUpdate({ dataURL: fileBuffer, fileName, filePath })
    // app.audioFileInput.value = filePath; // TODO
  } else {
    if (!songFile) {
      return // none selected // TODO: is this a way to clear?
    }
    const reader = new FileReader()
    player.userInputAudioFile = songFile
    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (event.target) {
        const dataURL = event.target.result
        setAndUpdate({ dataURL, fileName: songFile.name })
      }
    }
    reader.readAsDataURL(songFile)
  }
})

export { audioUrlChanged, loadLocalAudio }
